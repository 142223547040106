import React, { useState } from 'react';

import { auth } from '../util/firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

import { UserContext } from '../UserContext';

function Login() {
  const { currentUser, firebaseUser, updateFirebaseUser, isLoggedIn } = React.useContext(UserContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function tryLogin() {
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        updateFirebaseUser(user);
        alert('Successful sign in!');
      })
      .catch((err) => alert(err.message));
  }

  function trySignup() {
    createUserWithEmailAndPassword(auth, email, password)
      .then((user) => {
        updateFirebaseUser(user);
        alert('Successful sign in!');
      })
      .catch((err) => alert(err.message));
  }

  function tryLogout() {
    auth
      .signOut()
      .then(() => {
        updateFirebaseUser(null);
      })
      .catch((err) => alert(err.message));
  }

  return (
    <div className="signup">
      <form>
        <div>Is User Logged In? {isLoggedIn ? 'Yes' : 'No'}</div>

        <div>
          <label htmlFor="email">Email</label>
          <input onChange={(e) => setEmail(e.target.value)} type="text" id="email" />
        </div>

        <div>
          <label htmlFor="password">Password</label>
          <input onChange={(e) => setPassword(e.target.value)} type="password" id="password" />
        </div>

        <button type="button" onClick={tryLogin}>
          Log In
        </button>
        <button type="button" onClick={trySignup}>
          Sign Up
        </button>
        <button type="button" onClick={tryLogout}>
          Log Out
        </button>
      </form>

      <pre>{JSON.stringify(firebaseUser, null, 2)}</pre>
      <pre>{JSON.stringify(currentUser, null, 2)}</pre>
    </div>
  );
}

export default Login;
