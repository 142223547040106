import React, { useEffect } from 'react';

import { auth } from './util/firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { UserContext } from './UserContext';

import Login from './components/Login';

function App() {
  // State machine for returning logged-in user.
  const { updateFirebaseUser, updateCurrentUser } = React.useContext(UserContext);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      updateFirebaseUser(user);

      if (!user) return;

      user
        .getIdToken(true /* forceRefresh */)
        .then((idToken: string) => {
          return fetch('http://localhost:8787/user', {
            method: 'GET',
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((user) => {
          updateCurrentUser(user);
        })
        .catch(console.error);
    });
  }, []);

  return (
    <>
      <Login />
    </>
  );
}

export default App;
