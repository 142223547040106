import React, { useState, FC, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface UserContextInterface {
  firebaseUser: any;
  currentUser: any;
  isLoggedIn: boolean;
  updateFirebaseUser: (user: any) => void;
  updateCurrentUser: (user: any) => void;
}

const initialState: UserContextInterface = {
  firebaseUser: null,
  currentUser: null,
  isLoggedIn: false,
  updateFirebaseUser: () => {
    //
  },
  updateCurrentUser: () => {
    //
  },
};

export const UserContext = React.createContext<UserContextInterface>(initialState);

const StateMachine: FC<Props> = ({ children }: Props) => {
  const [firebaseUser, setFirebaseUser] = useState(initialState.firebaseUser);
  const [currentUser, setCurrentUser] = useState(initialState.currentUser);
  const [isLoggedIn, setIsLoggedIn] = useState(initialState.isLoggedIn);

  const updateFirebaseUser = (user: any) => {
    setFirebaseUser(user);
    setIsLoggedIn(!!user);
  };

  const updateCurrentUser = (user: any) => {
    setCurrentUser(user);
    setIsLoggedIn(!!user);
  };

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        currentUser,
        isLoggedIn,
        updateFirebaseUser,
        updateCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default StateMachine;
