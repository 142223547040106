'use strict';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC5fPRTGKza--_7kGx2UAJEli9H0pZ0U9I',
  authDomain: 'headlessr-a785e.firebaseapp.com',
  projectId: 'headlessr-a785e',
  storageBucket: 'headlessr-a785e.appspot.com',
  messagingSenderId: '653640363485',
  appId: '1:653640363485:web:fd1b52c6581709a3c61a34',
  measurementId: 'G-K617YWZBMS',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
